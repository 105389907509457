/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid, useToast } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import avatar11 from "assets/img/avatars/avatar11.svg";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FetchError from "utils/FetchError";

export default function Profile(props) {
  const [profileData, setProfileData] = useState({
    name:"Master Admin",
    role:"Master Admin",
    permissions:["All Windows"],
    lastSeen:"10:00 PM Yesterday"
  })
  const roles = {
    "master_admin": "Master Admin",
    "co_admin": "Co Admin",
    "user": "User",
  }
  const permissions = {
    "all": "All Windows",
    "wellwise_app": "Wellwise App",
    "products": "Products",
    "website_info": "Website Informatics",
    "orders": "Orders"
  }
  const toast = useToast()
  const history = useHistory()

  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          toast({
            position: "top",
            title: "Unauthorized",
            description: "Unauthorized access. Please login.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          history.push('/auth');
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/profile`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = await response.json();
        const profile = {...profileData}
        profile.name = result.profile.name
        profile.role = result.profile.position
        profile.permissions = result.profile.permissions
        setProfileData(profile)
        props.setUserName(result.name)
        console.log(profile)
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        let location = "/admin/default";
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
          location = "/auth"
          localStorage.removeItem('rosetteAuthToken');
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push(location)
      }
    };

    fetchData();
  }, [toast, history]);

  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.3fr 1.2fr 1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          banner={banner}
          avatar={avatar11}
          {...profileData}
        />
      </Grid>
    </Box>
  );
}
