import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import { LuLayoutGrid } from "react-icons/lu";
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
export default function UserTable(props) {
  
  const { columnsData, tableData, setTableData } = props;
  const [selectAll, setSelectAll] = useState(false)
  const columns = useMemo(() => {
    return columnsData
  }, [columnsData, tableData]);
  const data = useMemo(() => {
    return tableData;
  }, [tableData]);

  useEffect(() => {
    const allSelected = tableData.every((product) => product.name[1]);
    setSelectAll(allSelected);
  }, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;


  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleRowCheckboxChange = (index) => {
    const updatedProducts = [...tableData];
    updatedProducts[index].name[1] = !updatedProducts[index].name[1];
    setTableData(updatedProducts);
  };

  const handleHeaderCheckboxChange = () => {
    const newSelectAll = !selectAll;
    const updatedProducts = tableData.map(product => ({
      ...product,
      name: [product.name[0], newSelectAll],
    }));
    setTableData(updatedProducts);
    setSelectAll(newSelectAll);
  };


  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => {
                // if (column.render("Header") === "NAME") {
                //   return  <Th
                //             pe='10px'
                //             key={index}
                //             borderColor={borderColor}>
                //             <Flex align='center'
                //             fontSize={{ sm: "10px", lg: "12px" }}
                //             color='gray.400'
                //             >
                //               <Checkbox
                //                 isChecked={selectAll}
                //                 colorScheme='brandScheme'
                //                 me='10px'
                //                 onChange={() => handleHeaderCheckboxChange()}
                //               />
                //               <Text flexGrow='1' {...column.getHeaderProps(column.getSortByToggleProps())}>
                //               {column.render("Header")}
                //               </Text>
                //             </Flex>
                //           </Th>
                // } else{
                  return  <Th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            pe='10px'
                            key={index}
                            borderColor={borderColor}>
                            <Flex
                              justify='space-between'
                              align='center'
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color='gray.400'>
                                
                                  {/* <Text align='left'> */}
                                    {column.render("Header")}
                                  {/* </Text> */}
                            </Flex>
                          </Th>
                // }
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={rowIndex}>
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "Name") {
                    data = (
                      <Flex align='center' gap='10px'>
                        {/* <Checkbox
                          isChecked={cell.value[1]}
                          colorScheme='brandScheme'
                          me='10px'
                          onChange={() => handleRowCheckboxChange(rowIndex)}
                        /> */}
                        <Icon as={LuLayoutGrid} w='24px' h='24px' color={textColor} />
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value[0]}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Email") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Role") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Permissions") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "Status") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </Card>
  );
}
