// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";

import {
  columnsDataDevelopment,
  columnsDataCheck,
  userDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/wellwiseapp/variables/userData";
import CheckTable from "views/admin/wellwiseapp/components/CheckTable";
import ComplexTable from "views/admin/wellwiseapp/components/ComplexTable";
import DailyTraffic from "views/admin/wellwiseapp/components/DailyTraffic";
import PieCard from "views/admin/wellwiseapp/components/PieCard";
import Tasks from "views/admin/wellwiseapp/components/Tasks";
import TotalSpent from "views/admin/wellwiseapp/components/TotalSpent";
import WeeklyRevenue from "views/admin/wellwiseapp/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/wellwiseapp/variables/columnsData";
import tableDataCheck from "views/admin/wellwiseapp/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/wellwiseapp/variables/tableDataComplex.json";
import UserTable from "./components/UserTable";
import AddPushNotifications from "./components/AddPushNotifications";
import General from "./components/General";
import AddTip from "./components/AddTip";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FetchError from "utils/FetchError";

export default function WellWiseApp(props) {
  const toast = useToast();
  const history = useHistory();
  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          toast({
            position: "top",
            title: "Unauthorized",
            description: "Unauthorized access. Please login.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          history.push('/auth');
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/wellwise_app`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = await response.json();
        props.setUserName(result.name)
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        let location = "/admin/default";
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
          location = "/auth"
          localStorage.removeItem('rosetteAuthToken');
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push(location)
      }
    };

    fetchData();
  }, [toast, history]);
  
  const [userData, setUserData] = useState(tableDataCheck)
  // Chakra Color Mode
  const textColorSecondary = "secondaryGray.600";
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        // columns={{ base: 1, md: 2, lg: 3, "2xl": 5 }}
        gridTemplateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "1fr 1fr 1.2fr", "2xl": "1fr 1fr 1.2fr 1fr 1fr" }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Active Users'
          value='100'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Total Users'
          value='230'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          endContent={
            <Flex direction="column" align="center">
              {/* <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel> */}
              <Text 
                lineHeight='100%'
                color={textColorSecondary}
                fontSize={{
                  base: "sm",
                }}>In last</Text>
              <Select
                id='balance'
                outline="none"
                border="none"
                _focus={{outline: "none"}}
                // variant='mini'
                lineHeight='100%'
                color={textColorSecondary}
                fontSize={{
                  base: "sm",
                }}
                defaultValue='5'>
                <option value='5'>5 days</option>
                <option value='15'>15 days</option>
                <option value='30'>30 days</option>
              </Select>
            </Flex>
          }
          name='WellWise New Users'
          value='10'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Active Users'
          value='100'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Total Users'
          value='230'
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, xl: 3, "2xl": 4 }}
        gap='20px'
        mb='20px'>
          <AddTip
            minH='370px'
            maxH='370px'
            pe='20px'
            overflowY="scroll"
          />
          <AddPushNotifications
            minH='370px'
            maxH='370px'
            pe='20px'
            overflowY="scroll"
          />
          <AddPushNotifications
            minH='370px'
            maxH='370px'
            pe='20px'
            overflowY="scroll"
          />
          <AddPushNotifications
            minH='370px'
            maxH='370px'
            pe='20px'
            overflowY="scroll"
          />
        </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, "2xl": 2 }}
        gap='20px'
        mb='20px'>
          <UserTable columnsData={userDataCheck} tableData={userData} setTableData={setUserData} />
          <UserTable columnsData={userDataCheck} tableData={userData} setTableData={setUserData} />
        </SimpleGrid>
    </Box>
  );
}
