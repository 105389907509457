import {
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
function Activities() {
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'
          boxShadow='0px 40px 58px -20px rgba(112, 144, 176, 0.26)'>
          <Text color={textColor} fontSize='xl' fontWeight='600'>
            Active Live Users
          </Text>
        </Flex>
        <Flex
          align='center'
          minH={"250px"}
          justify='center'
          w='100%'
          px='22px'
          pb='20px'
          mb='10px'>
          <Text textAlign={"center"} w={"200px"} maxW={'100%'} color={textColor} fontSize='lg' fontWeight='500'>
            We shall work on this later
          </Text>
        </Flex>
      </Flex>
    </>
  );
}

export default Activities;
