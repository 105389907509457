// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";

export default function Banner(props) {
  const { banner, avatar, name, role, permissions, lastSeen } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );
  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      <Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
        {name}
      </Text>
      <Text color={textColorSecondary} fontSize='sm'>
        {role}
      </Text>
      <Flex justify="space-evenly" align="start" mt='26px'>
        <Flex mx='auto' justify="center" align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='xl' fontWeight='700'>
            Permissions
          </Text>
          <Box>
            {permissions.map((permission, index) => (
              <Text key={index} color={textColorSecondary} fontSize='sm' fontWeight='400'>
                {permission}
              </Text>
            ))}
          </Box>
        </Flex>
        <Flex mx='auto' justify="center" align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='xl' fontWeight='700'>
            Last Seen 
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            {lastSeen}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
