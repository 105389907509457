import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { TbDeviceMobileCharging } from "react-icons/tb";
import { RiLayoutMasonryFill } from "react-icons/ri";
// Admin Imports
import MainDashboard from "views/admin/default";
import WellWiseApp from "views/admin/wellwiseapp";
import WellWiseInfo from "views/admin/wellwiseinfo";
import NFTMarketplace from "views/admin/marketplace";
import UserSettings from "views/admin/userSettings";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Products from "views/admin/products";
import AddProduct from "views/admin/addproduct";
// import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import ForgotPassword from "views/auth/forgotpassword";
import ResetPassword from "views/auth/resetpassword";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
    breadcrumb: "Dashboard",
  },
  {
    name: "WellWise Application",
    layout: "/admin",
    path: "/wellwise-app",
    icon: <Icon as={TbDeviceMobileCharging} width='20px' height='20px' color='inherit' />,
    component: WellWiseApp,
    breadcrumb: "WellWise Application",
  },
  {
    name: "Website Infographics",
    layout: "/admin",
    path: "/wellwise-info",
    icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
    component: WellWiseInfo,
    breadcrumb: "Website Infographics",
  },
  // {
  //   name: "Products",
  //   layout: "/admin",
  //   icon: (
  //     <Icon
  //       as={RiLayoutMasonryFill}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   path: "/products",
  //   component: Products,
  //   breadcrumb: "Products",
  //   // items: [
  //   //   {
  //   //     name: "Add New Product", // New route for Add New Product
  //   //     layout: "/admin",
  //   //     path: "/products/addproduct",
  //   //     component: AddProduct,
  //   //     breadcrumb: "Add New Product",
  //   //   },
  //   // ],
  // },
  {
    name: "Products",
    layout: "/admin",
    icon: <Icon as={RiLayoutMasonryFill} width='20px' height='20px' color='inherit' />,
    path: "/products",
    component: Products,
    breadcrumb: "Products",
  },
  {
    name: "Add New Product",
    layout: "/admin",
    path: "/products/addproduct",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: AddProduct,
    hideInSidebar: true,
    breadcrumb: "Add New Product", // This property indicates that the route should not be displayed in the sidebar
  },
  {
    name: "User Settings",
    layout: "/admin",
    path: "/usersettings",
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: UserSettings,
    secondary: true,
    breadcrumb: "User Settings",
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width='20px'
  //       height='20px'
  //       color='inherit'
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  //   breadcrumb: "NFT Marketplace",
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
  //   path: "/data-tables",
  //   component: DataTables,
  //   breadcrumb: "Data Tables",
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
    breadcrumb: "Profile",
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignInCentered,
    breadcrumb: "Sign In",
    hideInSidebar: true,
  },
  {
    name: "Forgot Password",
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ForgotPassword,
    breadcrumb: "Forgot Password",
    hideInSidebar: true,
  },
  {
    name: "Reset Password",
    layout: "/auth",
    path: "/reset-password",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: ResetPassword,
    breadcrumb: "Reset Password",
    hideInSidebar: true,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: RTL,
  // },
];

export default routes;
