// Chakra imports
import { Box, Flex, FormControl, FormLabel, Grid, Heading, Icon, Input, Radio, RadioGroup, SimpleGrid, Stack, StackDivider, Text, Textarea, useColorModeValue, useToast } from "@chakra-ui/react";
import Dropzone from "views/admin/addproduct/components/Dropzone";
import tableDataCheck from "views/admin/addproduct/variables/tableDataCheck.json";
import React, { useEffect, useState } from "react";
import { SlCloudUpload } from "react-icons/sl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FetchError from "utils/FetchError";

export default function AddProduct(props) {

  const toast = useToast();
  const history = useHistory();
  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          toast({
            position: "top",
            title: "Unauthorized",
            description: "Unauthorized access. Please login.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          history.push('/auth');
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/products`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = await response.json();
        props.setUserName(result.name)
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        let location = "/admin/default";
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
          location = "/auth"
          localStorage.removeItem('rosetteAuthToken');
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push(location)
      }
    };

    fetchData();
  }, [toast, history]);
  
  const brandColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // Chakra Color Mode
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
    {/* Main Fields */}
    <Grid
      mb='14px'
      gridTemplateColumns={{ lg: "repeat(3, 1fr)", xl: "repeat(3, 1fr)", "2xl": "1fr 0.36fr" }}
      gap={{ base: "20px", xl: "20px" }}
      display={{ base: "block", lg: "grid" }}>
      <Flex
        flexDirection='column'
        gridArea={{ lg: "1 / 1 / 2 / 3" , xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
        <Text
          my='28px'
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          General
        </Text>
        <FormControl mb='20px' isRequired>
          <FormLabel>Product Name</FormLabel>
          <Input
          style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
          placeholder='Product name' />
        </FormControl>
        <Grid
          mb='20px'
          gridTemplateColumns={{ lg: "repeat(2, 1fr)", xl: "repeat(3, 1fr)" }}
          gap={{ base: "20px", xl: "20px" }}
          display={{ base: "block", lg: "grid" }}>
            <FormControl isRequired>
              <FormLabel>Product ID (SKU)</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Product ID (SKU)' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Quantity in Stock</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Quantity in Stock' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Category</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Category' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Net Price</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Net Price' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Sales Price (Discounted Price)</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Selling Price' />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Least Margin Price</FormLabel>
              <Input
              style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
              placeholder='Lowest selling proce' />
            </FormControl>
        </Grid>
        <FormControl mb='20px' isRequired>
          <FormLabel>Description </FormLabel>
          <Textarea
            style={{border: "1px solid #A3AED0", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}}
            placeholder='Describe the short note about product'
            size='md'
            resize={"vertical"}
          />
        </FormControl>
        <FormControl mb='20px' isRequired>
          <FormLabel>Product Images</FormLabel>
          <Flex>
            <Dropzone
              w={{ base: "200px" }}
              content={
                <Flex justify={"center"} align={"center"} direction={"column"} w={"100%"} style={{aspectRatio:"1"}}>
                    <Icon as={SlCloudUpload} w='80px' h='80px' color={brandColor} />
                    <Text whiteSpace={"normal"} fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                      Drop your images here or select click to browse
                    </Text>
                </Flex>
              }
          />
        </Flex>
        </FormControl>
      </Flex>
      <Flex
        px={"1rem"}
        flexDirection='column'
        gap={"1rem"}
        gridArea={{ lg: "1 / 3 / 2 / 4" , xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Flex
            px={"1rem"}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            direction={"column"} align={"left"}
          >
            <Text
              my='14px'
              color={textColor}
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
              Product Status
            </Text>
            <FormControl my='10px'>
              <FormLabel fontSize='19px' as={"u"}>Status</FormLabel>
              <RadioGroup fontSize='19px' defaultValue='1'>
                <Stack>
                  <Radio value='1'>Active</Radio>
                  <Radio value='0'>Disabled</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl my='10px'>
              <FormLabel fontSize='19px' as={"u"}>Visibility  </FormLabel>
              <RadioGroup fontSize='19px' defaultValue='1'>
                <Stack>
                  <Radio value='0'>Coming Soon</Radio>
                  <Radio value='1'>Open Now</Radio>
                  <Radio value='2'>Out of Stock</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
          <Flex
            px={"1rem"}
            boxShadow={"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}
            direction={"column"} align={"left"}
          >
            <Text
              my='14px'
              color={textColor}
              fontSize='22px'
              fontWeight='700'
              lineHeight='100%'>
              Product Variants 
            </Text>
            <FormControl my='10px'>
              <FormLabel fontSize='19px' as={"u"}>Status</FormLabel>
              <RadioGroup fontSize='19px' defaultValue='1'>
                <Stack>
                  <Radio value='1'>Active</Radio>
                  <Radio value='0'>Disabled</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl my='10px'>
              <FormLabel fontSize='19px' as={"u"}>Visibility  </FormLabel>
              <RadioGroup fontSize='19px' defaultValue='1'>
                <Stack>
                  <Radio value='0'>Coming Soon</Radio>
                  <Radio value='1'>Open Now</Radio>
                  <Radio value='2'>Out of Stock</Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Flex>
      </Flex>
    </Grid>
    {/* Delete Product */}
  </Box>
  );
}
