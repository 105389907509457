// Chakra imports
import { Button, Flex, HStack, Icon, List, ListIcon, ListItem, SimpleGrid, Text, UnorderedList, VStack, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { FaCircle, FaFileDownload, FaFileUpload, FaPlusCircle } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import { BsDot } from "react-icons/bs";
import { TbMessageCircleHeart } from "react-icons/tb";
import Information from "views/admin/wellwiseapp/components/Information";
import { GoDownload, GoUpload } from "react-icons/go";
import { FiUpload } from "react-icons/fi";
// Assets
export default function AddTip(props) {
  
  const iconColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("brand.500", "white");
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest} display="flex" justifyContent="space-between">
      <Flex direction="column" gap={"10px"}>
        <Flex align='center' gap='6px' mb='20px'>
          <Icon as={TbMessageCircleHeart} w='26px' h='26px' color={textColor} />
          <Text
            color={textColor}
            fontSize='18px'
            fontWeight='700'
            lineHeight='100%'>
            Add  Tip (Quotes) of the Day
          </Text>
        </Flex>
        <UnorderedList display="flex" flexDirection="column" gap="10px" mb="10px">
          <ListItem display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Flex align="center" gap="2px">
              <ListIcon as={FaCircle} color="brand.500"  boxSize={2} />
              <Text fontSize="md" fontWeight="500">Download present list of Quotes</Text>
            </Flex>
            <Icon as={GoDownload} w='24px' h='24px'/>
          </ListItem>
          <ListItem display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Flex align="center" gap="2px">
              <ListIcon as={FaCircle} color="brand.500"  boxSize={2} />
              <Text fontSize="md" fontWeight="500">Upload New  list of Quotes </Text>
            </Flex>
            <Icon as={GoUpload} w='24px' h='24px'/>
          </ListItem>
        </UnorderedList>
      </Flex>
      <Flex direction="column" gap={"10px"}>
        <Text fontSize="xl" fontWeight="bold" color={textColor}>
          Add Custom Quotes for Specific type of user category
        </Text>
        <Button
          fontSize='sm'
          variant='brand'
          fontWeight='500'
          w='100%'
          h='50'
          mb='24px'>
          Add Custom 
        </Button>
      </Flex>
    </Card>
  );
}
