// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

export default function AdminNavbar(props) {
	const [ scrolled, setScrolled ] = useState(false);
	const location = useLocation();
	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	});

	const { secondary, message, brandText, routes } = props;

	// Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
	let mainText = useColorModeValue('navy.700', 'white');
	let secondaryText = useColorModeValue('gray.700', 'white');
	let navbarPosition = 'fixed';
	let navbarFilter = 'none';
	let navbarBackdrop = 'blur(20px)';
	let navbarShadow = 'none';
	let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
	let navbarBorder = 'transparent';
	let secondaryMargin = '0px';
	let paddingX = '15px';
	let gap = '0px';
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	const getBreadcrumbItems = () => {
		const pathnames = location.pathname.split('/').filter((x) => x);
		let breadcrumbItems = [
		  <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px' key="Pages">
			<BreadcrumbLink href='#' color={secondaryText}>
			  Pages
			</BreadcrumbLink>
		  </BreadcrumbItem>
		];
	
		pathnames.forEach((value, index) => {
		  const route = routes.find((route) => route.path.includes(value));
		  if (route) {
			breadcrumbItems.push(
			  <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px' key={route.breadcrumb}>
				<BreadcrumbLink href='#' color={secondaryText}>
				  {route.breadcrumb}
				</BreadcrumbLink>
			  </BreadcrumbItem>
			);

		  }
		});
	
		return breadcrumbItems;
	};

	// const findRoute = (routes, path) => {
	// 	for (const route of routes) {
	// 	  if (route.path === path || route.layout + route.path === path) {
	// 		return route;
	// 	  }
	// 	  if (route.items) {
	// 		// for (const subRoute of route.items) {
	// 		//   if (route.layout + subRoute.path === path) {
	// 		// 	return subRoute;
	// 		//   }
	// 		// }
	// 		return findRoute(route.items, path)
	// 	  }
	// 	}
	// 	return null;
	//   };
	
	//   const getBreadcrumbItems1 = () => {
	// 	const pathnames = location.pathname.split('/').filter((x) => x);
	// 	let breadcrumbItems = [
	// 	  <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px' key="Pages">
	// 		<BreadcrumbLink as={Link} to='/' color={secondaryText}>
	// 		  Pages
	// 		</BreadcrumbLink>
	// 	  </BreadcrumbItem>
	// 	];
	
	// 	let currentPath = '';
	// 	pathnames.forEach((value, index) => {
	// 	  currentPath += `/${value}`;
	// 	  const route = findRoute(routes, currentPath);
	// 	  console.log(currentPath, route)
	// 	  if (route) {
	// 		breadcrumbItems.push(
	// 		  <BreadcrumbItem color={secondaryText} fontSize='sm' mb='5px' key={route.breadcrumb}>
	// 			<BreadcrumbLink as={Link} to={currentPath} color={secondaryText}>
	// 			  {route.breadcrumb}
	// 			</BreadcrumbLink>
	// 		  </BreadcrumbItem>
	// 		);
	// 	  }
	// 	});
	
	// 	return breadcrumbItems;
	//   };

	return (
		<Box
			position={navbarPosition}
			boxShadow={navbarShadow}
			bg={navbarBg}
			borderColor={navbarBorder}
			filter={navbarFilter}
			backdropFilter={navbarBackdrop}
			backgroundPosition='center'
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH='75px'
			justifyContent={{ xl: 'center' }}
			lineHeight='25.6px'
			mx='auto'
			mt={secondaryMargin}
			pb='8px'
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			px={{
				sm: paddingX,
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			pt='8px'
			top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 8%)',
				lg: 'calc(100vw - 6%)',
				xl: 'calc(100vw - 350px)',
				'2xl': 'calc(100vw - 365px)'
			}}>
			<Flex
				w='100%'
				flexDirection={{
					sm: 'column',
					md: 'row'
				}}
				alignItems={{ xl: 'center' }}
				mb={gap}>
				<Box mb={{ sm: '8px', md: '0px' }}>
					<Breadcrumb>
						{getBreadcrumbItems()}
					</Breadcrumb>
					{/* Here we create navbar brand, based on route name */}
					<Link
						color={mainText}
						href='#'
						bg='inherit'
						borderRadius='inherit'
						fontWeight='bold'
						fontSize='34px'
						_hover={{ color: { mainText } }}
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}>
						{brandText}
					</Link>
				</Box>
				<Box className='here' ms='auto' w={{ sm: '100%', md: 'unset' }}>
					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
						userName={props.userName}
						scrolled={scrolled}
					/>
				</Box>
			</Flex>
			{secondary ? <Text color='white'>{message}</Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
