import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import illustration1 from "assets/img/auth/auth1.jpg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FetchError from "utils/FetchError";
import { FaChevronLeft } from "react-icons/fa";

function ForgotPassword() {

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [formData, setFormData] = useState({
    email: undefined,
    otp: undefined
  });
  const [emailSuccessful, setEmailSuccessful] = useState(false)
  const [errors, setErrors] = useState({});

  const toast = useToast();
  const history = useHistory()

  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = response.json();
        toast({
          position: "top",
          title: "Already logged in.",
          description: result.message,
          // description: "You have successfully logged in.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.push("/admin");
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        
        localStorage.removeItem('rosetteAuthToken');
      }
    };

    fetchData();
  }, [toast, history]);
  

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = (formData) => {
    const errors = {};

    if(formData.email === undefined) return false

    if (!validateEmail(formData.email)) {
      errors.email = "Please enter a valid email address.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm(formData)) {
      return;
    }
    const toastId = toast({
        position: "top",
        title: "Sending Verification email...",
        description: 'Your request is being processed.',
        isClosable: false,
        status: 'info',
        duration: null, // make the toast persistent
    });
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/sendotp/${formData.email}`, {
        method: 'GET',
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new FetchError(response.status, errorData.error);
      }

      const result = await response.json();
      setEmailSuccessful(true)
      toast.update( toastId, {
        position: "top",
        title: "Verification Email Sent",
        description: result.message,
        // description: "You have successfully logged in.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        
        if (errorStatus === 400) {
          errorTitle = "Invalid request";
        } else if (errorStatus === 422) {
          errorTitle = "Validation failed";
        } else if (errorStatus === 404) {
          errorTitle = "Invalid Email";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
        } 
        toast.update( toastId, {
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    }
  };
  const handleOTPSubmit = async (e) => {
    e.preventDefault();

    const error = {}
    if (formData.otp.length < 4 || formData.otp.length > 4) {
      error.otp = "OTP must be 4 digits only.";
      error.otp = "OTP must be 4 digits only.";
      setErrors(error);
      return
    }
    else {
      setErrors(error);
    }
    
    console.log(formData)
    const data = new FormData();
    data.append('email', formData.email);
    data.append('otp', formData.otp);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/forgotpassword/checkotp`, {
        method: 'POST',
        body: data,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new FetchError(response.status, errorData.error);
      }

      const result = await response.json();
      console.log(result)
      localStorage.setItem('tempToken', result.tempToken);
      toast({
        position: "top",
        title: "OTP Verified.",
        // description: result.message,
        description: "Redirecting in few seconds...",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      
      setTimeout(() => {
            history.push("/auth/reset-password")
        }, 2000)
    } catch (error) {
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        
        if (errorStatus === 422) {
          errorTitle = "Validation failed";
        } else if (errorStatus === 404) {
          errorTitle = "Invalid Email";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
        } 
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration1} image={illustration}>
      <Flex
        // maxW={{ base: "100%", md: "420px" }}
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "30px", md: "8vh" }}
        flexDirection='column'>
          <Box
            mb={{ base: "10px", md: "4vh"}}
          >
            <NavLink
              to='/auth/sign-in'
              style={() => ({
                width: "fit-content",
              })}>
              <Flex
                align='center'
                ps={{ base: "25px", lg: "0px" }}
                pt={{ lg: "0px", xl: "0px" }}
                w='fit-content'>
                <Icon
                  as={FaChevronLeft}
                  me='12px'
                  h='13px'
                  w='8px'
                  color='secondaryGray.600'
                />
                <Text ms='0px' fontSize='sm' color='secondaryGray.600'>
                  Back to Sign-in
                </Text>
              </Flex>
            </NavLink>
          </Box>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Forgot Password
          </Heading>
          <Text
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Verify your email to reset the password!
          </Text>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Note: This page is dedicated only for Governing Members of Rosette Smart Life
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <form style={{opacity: emailSuccessful? "0.8": "1"}} onSubmit={handleEmailSubmit}>
            <FormControl mb='18px' id="email" isRequired isInvalid={errors.email}>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                htmlFor="email"
                mb='8px'>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='mail@rosettesmartlife.com'
                mb='6px'
                readOnly={emailSuccessful}
                disabled={emailSuccessful}
                fontWeight='500'
                size='lg'
                name="email"
                value={formData.email}
                onChange={e => handleChange(e)}
              />
              {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
            </FormControl>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              mb='24px'
              type={emailSuccessful? "button" : "submit"}
              >
              Generate OTP
            </Button>
          </form>
          <form style={{opacity: !emailSuccessful? "0.8": "1"}} onSubmit={handleOTPSubmit}>
            <FormControl mb='18px' id="otp" isRequired isInvalid={errors.otp}>
              <FormLabel
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                htmlFor="otp"
                color={textColor}
                display='flex'>
                OTP<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  placeholder='Enter OTP received'
                readOnly={!emailSuccessful}
                disabled={!emailSuccessful}
                  size='lg'
                  name="otp"
                  type="number"
                  variant='auth'
                  value={formData.otp}
                  onChange={e => handleChange(e)}
                />
              </InputGroup>
              {errors.otp && <Text color="red.500" fontSize="sm">{errors.otp}</Text>}
            </FormControl>
            <Flex justifyContent='right' align='center' mb='24px'>
              {/* <NavLink to='/auth/forgot-password'> */}
                <Button
                  onClick={!emailSuccessful ? null : handleEmailSubmit}
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Resend OTP?
                </Button>
              {/* </NavLink> */}
            </Flex>
            <Button
              fontSize='sm'
              variant='brand'
              fontWeight='500'
              w='100%'
              h='50'
              type={!emailSuccessful? "button" : "submit"}
              mb='24px'>
              Verify OTP  
            </Button>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default ForgotPassword;
