// Chakra imports
import {
  // Avatar,
  Box,
  Flex,
  // FormLabel,
  Icon,
  // Select,
  SimpleGrid,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
// import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import {
  // MdAddTask,
  // MdAttachMoney,
  MdBarChart,
  // MdFileCopy,
} from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FetchError from "utils/FetchError";
// import CheckTable from "views/admin/default/components/CheckTable";
// import ComplexTable from "views/admin/default/components/ComplexTable";
// import DailyTraffic from "views/admin/default/components/DailyTraffic";
// import PieCard from "views/admin/default/components/PieCard";
// import Tasks from "views/admin/default/components/Tasks";
// import TotalSpent from "views/admin/default/components/TotalSpent";
import WebsiteTraffic from "views/admin/default/components/WebsiteTraffic";
// import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
// import {
//   columnsDataCheck,
//   columnsDataComplex,
// } from "views/admin/default/variables/columnsData";
// import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
// import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";

export default function DefaultPage(props) {
  const toast = useToast()
  const history = useHistory()

  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          toast({
            position: "top",
            title: "Unauthorized",
            description: "Unauthorized access. Please login.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          history.push('/auth');
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = await response.json();
        props.setUserName(result.name)
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        let location = "/admin/default";
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
          location = "/auth"
          localStorage.removeItem('rosetteAuthToken');
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
          location = "/auth"
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
          location = "/auth"
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push(location)
      }
    };

    fetchData();
  }, [toast, history]);
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Active Users'
          value='100'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise Total Users'
          value='230'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='WellWise New Users'
          value='10'
        />
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Spend this month'
          value='$642.39'
        /> */}
        {/* <MiniStatistics growth='+23%' name='Sales' value='$574.34' /> */}
        {/* <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        /> */}
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
              icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
            />
          }
          name='New Tasks'
          value='154'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        /> */}
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Total Orders Placed'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Onders In Transit'
          value='230'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Orders Placed'
          value='10'
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <WebsiteTraffic />
        {/* <WeeklyRevenue /> */}
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid>
      </SimpleGrid> */}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
