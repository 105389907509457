// Chakra imports
import { Flex, Icon, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { FaPlusCircle } from "react-icons/fa";
import { IoIosNotifications } from "react-icons/io";
import Information from "views/admin/wellwiseapp/components/Information";

// Assets
export default function AddPushNotifications(props) {
  
  const iconColor = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("brand.500", "white");
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      <Flex justify='space-between' mb='20px' align='center'>
        <Flex align='center' gap='6px'>
          <Icon as={IoIosNotifications} w='26px' h='26px' color={textColor} />
          <Text
            color={textColor}
            fontSize='18px'
            fontWeight='700'
            lineHeight='100%'>
            Add Push Notifications
          </Text>
        </Flex>
        <Icon as={FaPlusCircle} color={iconColor} w='20px' h='20px' />
      </Flex>
      <SimpleGrid columns='1' gap='10px'>
        <Information
          boxShadow={cardShadow}
          title='Happy Diwali '
          value='19 Nov 2024 - 08:00AM'
        />
        <Information
          boxShadow={cardShadow}
          title='Light up your Lifestyle with Rosette'
          value='19 Nov 2024 - 11:30AM'
        />
      </SimpleGrid>
    </Card>
  );
}
