

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import TableTopCreators from "views/admin/userSettings/components/TableTopCreators";
import HistoryItem from "views/admin/userSettings/components/HistoryItem";
import tableDataCheck from "views/admin/userSettings/variables/tableDataCheck.json";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  productsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/userSettings/variables/columnsData";
import UserTable from "views/admin/userSettings/components/UserTable";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Card from "components/card/Card";

import tableDataTopCreators from "views/admin/userSettings/variables/tableDataTopCreators.json";
import { tableColumnsTopCreators } from "views/admin/userSettings/variables/tableColumnsTopCreators";

// Assets
import Nft1 from "assets/img/nfts/Nft1.png";
import Nft2 from "assets/img/nfts/Nft2.png";
import Nft3 from "assets/img/nfts/Nft3.png";
import Nft4 from "assets/img/nfts/Nft4.png";
import Nft5 from "assets/img/nfts/Nft5.png";
import Nft6 from "assets/img/nfts/Nft6.png";
import ActiveUsers from "./components/ActiveUsers";
import Activities from "./components/Activities";
import FetchError from "utils/FetchError";
export default function UserSettings(props) {

  const toast = useToast();
  const history = useHistory();
  useEffect(() => {

    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem('rosetteAuthToken');
        if (!authToken) {
          toast({
            position: "top",
            title: "Unauthorized",
            description: "Unauthorized access. Please login.",
            status: "warning",
            duration: 3000,
            isClosable: true,
          });
          history.push('/auth');
          return
        }
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/user_settings`, {
          method: 'GET',
          headers: {
            'Authorization': authToken
          }
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new FetchError(response.status, errorData.error);
        }
        const result = await response.json();
        props.setUserName(result.name)
      } catch (error) {
        console.log(error)
        let errorTitle = "Unexpected Error";
        let errorMessage = error.message || "Something went wrong!";
        let errorStatus = error.status || 500;
        let location = "/admin/default";
        
        if (errorStatus === 401) {
          errorTitle = "Unauthorized Access";
          location = "/auth"
          localStorage.removeItem('rosetteAuthToken');
        } else if (errorStatus === 403) {
          errorTitle = "Forbidden";
        } else if (errorStatus === 404) {
          errorTitle = "Not Found";
          errorMessage = "Resource not found.";
        } else if (errorStatus === 500) {
          errorTitle = "Internal Server Error";
          errorMessage = "Internal server error. Please try again later.";
        }
        toast({
          position: "top",
          title: errorTitle,
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        history.push(location)
      }
    };

    fetchData();
  }, [toast, history]);
  
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [productData, setProductData] = useState(tableDataCheck)
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          User Settings Page
        </Text>
        {/* <NavLink to={'/admin/products/addproduct'}> */}
          <Button
            w='180px'
            minW='180px'
            variant='brand'
            fontWeight='500'>
            Add New User 
          </Button>
        {/* </NavLink> */}
      </Flex>
      <Grid
        mb='20px'
        gridTemplateColumns={{ xl: "repeat(3, 1fr)", "2xl": "1fr 0.36fr" }}
        gap={{ base: "20px", xl: "20px" }}
        display={{ base: "block", xl: "grid" }}>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 1 / 2 / 3", "2xl": "1 / 1 / 2 / 2" }}>
          <UserTable columnsData={productsDataCheck} tableData={productData} setTableData={setProductData} />
        </Flex>
        <Flex
          flexDirection='column'
          gridArea={{ xl: "1 / 3 / 2 / 4", "2xl": "1 / 2 / 2 / 3" }}>
          <Card px='0px' mb='20px'>
            <ActiveUsers />
          </Card>
          <Card px='0px' mb='20px'>
            <Activities />
          </Card>
        </Flex>
      </Grid>
      {/* Delete Product */}
    </Box>
  );
}
